.news-card {
  display: flex;
  flex-direction: column;
  position: relative;
  overflow: hidden;
  background-color: #fff;
  width: 400px;
  height: 576px;
  list-style: none;
  margin: 0;
  padding: 0;
  border-radius: 16px;
}

.news-card:hover {
  cursor: pointer;
}

.news-card__keyword {
  position: absolute;
  top: 24px;
  left: 24px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 22px;
  background: #ffffff;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #000000;
  border-radius: 10px;
}

.news-card__save-button,
.news-card__delete-button {
  position: absolute;
  top: 24px;
  right: 24px;
  background-color: #fff;
  background-image: url('../../images/save-icon.svg');
  background-repeat: no-repeat;
  background-position: center;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  outline: none;
  transition: background 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.news-card__save-button {
  background-image: url('../../images/save-icon.svg');
}

.news-card__save-button:hover {
  background-image: url('../../images/save-icon-hover.svg');
}

.news-card__save-button_active,
.news-card__save-button_active:hover {
  background-image: url('../../images/save-icon-active.svg');
}

.news-card__delete-button {
  background-image: url('../../images/delete-icon.svg');
}

.news-card__delete-button:hover {
  background-image: url('../../images/delete-icon-hover.svg');
}

.news-card__delete-button:hover .news-card__delete-text {
  width: 182px;
  font-size: 12px;
  color: #000;
}

.news-card__save-button:hover .news-card__save-text {
  width: 182px;
  font-size: 12px;
  color: #000;
}

.news-card__save-text,
.news-card__delete-text {
  position: absolute;
  right: 45px;
  width: 0;
  height: 40px;
  overflow: hidden;
  text-overflow: clip;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fff;
  color: transparent;
  font-size: 0;
  line-height: 14px;
  font-weight: 500;
  box-shadow: 0px 10px 22px rgba(59, 74, 116, 0.1);
  border-radius: 10px;
  outline: none;
  transition: width 0.5s cubic-bezier(0.215, 0.61, 0.355, 1),
    font 0.2s cubic-bezier(0.19, 1, 0.22, 1), color 0.25s cubic-bezier(0.39, 0.575, 0.565, 1);
}

.news-card__container {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.news-card__image {
  width: 400px;
  height: 272px;
}

.news-card__text-container {
  display: flex;
  flex-direction: column;
  padding: 22px;
  height: 100%;
}

.news-card__date {
  font-family: 'Source Sans Pro';
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  color: #b6bcbf;
  margin: 0;
}

.news-card__title {
  font-family: 'Roboto-Slab';
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  color: #1a1b22;
  margin: 0;
  padding-top: 15px;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-card__description {
  display: inline-block;
  font-weight: normal;
  font-size: 16px;
  line-height: 22px;
  color: #1a1b22;
  margin: auto 0 0 0;
  padding: 0;
  height: 110px;
  display: -webkit-box;
  -webkit-line-clamp: 5;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.news-card__source {
  font-family: 'Roboto-Slab';
  font-weight: bold;
  font-size: 16px;
  line-height: 20px;
  letter-spacing: 0.4px;
  text-transform: uppercase;
  color: #b6bcbf;
  margin: 0;
  padding-top: 12px;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 1439px) {
  .news-card {
    width: 300px;
    height: 520px;
  }

  .news-card__image {
    width: 300px;
    height: 190px;
  }

  .news-card__title {
    font-size: 24px;
  }

  .news-card__save-button,
  .news-card__delete-button {
    top: 14px;
    right: 14px;
  }

  .news-card__delete-button:hover .news-card__delete-text {
    width: 120px;
  }

  .news-card__keyword {
    top: 14px;
    left: 14px;
  }
}

@media screen and (max-width: 1295px) {
  .news-card {
    width: 260px;
    height: 520px;
  }

  .news-card__description {
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }

  .news-card__image {
    width: 260px;
    height: 180px;
  }

  .news-card__title {
    font-size: 24px;
  }

  .news-card__delete-button:hover .news-card__delete-text {
    width: 0;
  }

  .news-card__save-button,
  .news-card__delete-button {
    top: 12px;
    right: 12px;
  }

  .news-card__keyword {
    top: 12px;
    left: 12px;
  }
}

@media screen and (max-width: 1023px) {
  .news-card {
    width: 224px;
    height: 420px;
  }

  .news-card__text-container {
    padding: 16px;
  }

  .news-card__title {
    font-size: 22px;
    line-height: 24px;
  }

  .news-card__description {
    height: 88px;
    -webkit-line-clamp: 4;
  }

  .news-card__image {
    width: 224px;
    height: 150px;
  }

  .news-card__save-button:hover .news-card__save-text {
    width: 0;
  }

  .news-card__save-button,
  .news-card__delete-button {
    top: 8px;
    right: 8px;
  }

  .news-card__keyword {
    top: 8px;
    left: 8px;
  }
}

@media screen and (max-width: 767px) {
  .news-card {
    width: 288px;
    height: 440px;
  }

  .news-card__text-container {
    padding: 16px;
  }

  .news-card__title {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    padding-top: 10px;
  }

  .news-card__description {
    height: 88px;
    -webkit-line-clamp: 4;
    padding-top: 10px;
  }

  .news-card__image {
    width: 288px;
    height: 196px;
  }

  .news-card__source {
    padding-top: 8px;
  }

  .news-card__save-button,
  .news-card__delete-button {
    top: 16px;
    right: 16px;
  }

  .news-card__keyword {
    top: 16px;
    left: 16px;
  }
}