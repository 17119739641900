@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./Inter/inter-400-regular.woff2) format('woff2'),
    url(./Inter/inter-400-regular.woff) format('woff');
}

@font-face {
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./Inter/inter-500.woff2) format('woff2'), url(./Inter/inter-500.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./Roboto/roboto-400-regular.woff2) format('woff2'),
    url(./Roboto/roboto-400-regular.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url(./Roboto/roboto-500.woff2) format('woff2'), url(./Roboto/roboto-500.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./Roboto/roboto-700.woff2) format('woff2'), url(./Roboto/roboto-700.woff) format('woff');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 900;
  font-display: swap;
  src: url(./Roboto/roboto-900.woff2) format('woff2'), url(./Roboto/roboto-900.woff) format('woff');
}

@font-face {
  font-family: 'Roboto-Slab';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url(./RobotoSlab/roboto-slab-400-regular.woff2) format('woff2'),
    url(./RobotoSlab/roboto-slab-400-regular.woff) format('woff');
}

@font-face {
  font-family: 'Roboto-Slab';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url(./RobotoSlab/roboto-slab-700.woff2) format('woff2'),
    url(./RobotoSlab/roboto-slab-700.woff) format('woff');
}

@font-face {
  font-family: 'Source Sans Pro';
  font-style: normal;
  font-weight: 400;
  src: url('./SourceSansPro/source-sans-pro-v18-latin-regular.woff2') format('woff2'),
    url('./SourceSansPro/source-sans-pro-v18-latin-regular.woff') format('woff');
}
