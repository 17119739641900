.nothing-found {
  position: relative;
  background-color: #f5f6f7;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nothing-found__container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.nothing-found__container_hidden {
  visibility: hidden;
}

.nothing-found__title {
  margin: 0;
  padding-bottom: 16px;
  font-family: 'Roboto-Slab';
  font-weight: normal;
  font-size: 26px;
  line-height: 30px;
  text-align: center;
  color: #1a1b22;
}

.nothing-found__text {
  width: 266px;
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #b6bcbf;
}
