.popup {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  visibility: hidden;
  opacity: 0;
  transition: visibility 0.6s ease-in-out, opacity 0.6s ease-in-out, pointer-events 0.6s ease-in-out;
  background-color: rgba(0, 0, 0, 0.5);
  content: ' ';
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
  z-index: 15;
}

.popup_opened {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
}

.popup__container {
  margin-top: 207px;
  box-sizing: border-box;
  position: relative;
  width: 430px;
  background-color: #fff;
  border-radius: 16px;
  box-shadow: 0px 0px 25px rgba(0, 0, 0, 0.15);
  padding: 34px 36px 28px 36px;
}

.popup__container_type_info {
  padding: 34px 36px 50px 36px;
}

.popup__form {
  display: flex;
  flex-direction: column;
}

.popup__title {
  margin: 0 0 22px 0;
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #1a1b22;
}

.popup__label {
  font-family: 'Inter';
  font-weight: normal;
  font-size: 12px;
  line-height: 15px;
  color: #2f71e5;
}

.popup__input {
  width: 100%;
  height: 28px;
  border: none;
  font-size: 14px;
  line-height: 17px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  outline: none;
}

.popup__error {
  display: inline-block;
  color: #f00;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.25;
  min-height: 15px;
  min-width: 15px;
  margin: 3px 0 10px 0;
}

.popup__form-error {
  display: inline-block;
  color: #f00;
  text-align: center;
  font-size: 12px;
  font-weight: normal;
  line-height: 1.25;
  min-height: 15px;
  min-width: 15px;
  margin: 3px 0 8px 0;
}

.popup__save-button {
  height: 50px;
  width: 100%;
  padding: 0;
  color: #fff;
  background: #2f71e5;
  box-shadow: 0px 5px 15px rgba(14, 26, 57, 0.2);
  border-radius: 100px;
  font-size: 18px;
  font-weight: normal;
  line-height: 1.2;
  border: none;
  opacity: 1;
  transition: background 0.5s cubic-bezier(0.165, 0.84, 0.44, 1);
}

.popup__save-button:hover {
  cursor: pointer;
  background-color: #347eff;
}

.popup__save-button:active {
  background-color: #2a65cc;
}

.popup__save-button_disabled {
  pointer-events: none;
  background-color: #e6e8eb;
  color: #b6bcbf;
}

.popup__bottom-title {
  font-family: 'Inter';
  font-weight: normal;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  color: #000000;
  padding-top: 16px;
}

.popup__bottom-title_type_info {
  font-size: 18px;
  line-height: 22px;
  display: flex;
}

.popup__bottom-link {
  color: #2f71e5;
  text-decoration: none;
}

.popup__close-button {
  background-image: url('../../images/close-icon.svg');
  background-color: rgba(0, 0, 0, 0);
  background-size: cover;
  height: 40px;
  width: 40px;
  border: none;
  padding: 0;
  position: absolute;
  top: -36px;
  right: -36px;
  -webkit-transition: opacity 0.1s ease-out 0.1s;
  -o-transition: opacity 0.1s ease-out 0.1s;
  transition: opacity 0.1s ease-out 0.1s;
}

.popup__close-button:hover {
  opacity: 0.6;
  cursor: pointer;
}

.popup__message {
  margin: 0;
  padding: 14px 0;
  font-family: 'Roboto';
  font-weight: 900;
  font-size: 24px;
  line-height: 28px;
  color: #000000;
}

@media screen and (max-width: 495px) {
  .popup__container {
    width: 100vw;
    height: 560px;
    margin-top: 56px;
    padding: 16px;
  }

  .popup__title {
    margin: 0 0 18px 0;
    font-size: 24px;
    line-height: 28px;
  }

  .popup__save-button {
    font-size: 14px;
    width: 288px;
    height: 64px;
    margin: 0 auto;
  }

  .popup__close-button {
    right: 16px;
    top: -36px;
    width: 24px;
    height: 24px;
  }
}
