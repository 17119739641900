.preloader {
  z-index: 1;
  display: block;
  width: 50px;
  height: 50px;
  border: 4px solid #444;
  border-bottom-color: #888;

  border-radius: 50%;

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;

  animation: spin 0.75s infinite linear;
}

.preloader_hidden {
  visibility: hidden;
}

.preloader__title {
  z-index: 1;
  position: absolute;
  top: 67%;
  left: 0;
  width: 100%;
  margin: 0;
  font-weight: normal;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #b6bcbf;
}

.preloader__title_hidden {
  visibility: hidden;
}

@keyframes spin {
  100% {
    transform: rotate(360deg);
  }
}
