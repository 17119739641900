.header {
  transform-style: preserve-3d;
  z-index: 2;
  position: absolute;
  top: 0;
  box-sizing: border-box;
  width: 100%;
  height: 80px;
  padding: 0 104px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: rgba(196, 196, 196, 0.01);
  box-shadow: inset 0px -1px 0px rgba(255, 255, 255, 0.2);
}

.header_theme_light {
  background: #fff;
  box-shadow: inset 0px -1px 0px #d1d2d6;
}

.header__title {
  margin: 0;
  color: #fff;
  font-family: 'Roboto-Slab';
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
}

.header__title_theme_light {
  color: #1a1b22;
}

@media screen and (max-width: 1023px) {
  .header {
    padding: 0 40px;
    height: 66px;
  }
}

@media screen and (max-width: 767px) {
  .header {
    padding: 0 16px;
    height: 56px;
  }

  .header__title {
    font-size: 16px;
  }
}

@media screen and (max-width: 495px) {
  .header {
    flex-wrap: wrap;
    transition: background 0.15s cubic-bezier(0.6, 0.04, 0.98, 0.335);
  }

  .header__title {
    display: flex;
    align-items: center;
    height: 56px;
  }
}
